import helpers from "@/helpers/index.js"
import passConstants from "@/constants/passConstants"
import { apiV6nocache } from "@/store/modules/axiousWithCache.js"
import moment from "moment-timezone"

const state = {
  totalPasses: [],
  myPasses: [],
  waitingApproval: [],
  aptPassesCounters: new Map(),
  passesFromDashboardTable: new Map(),
  appointmentMetrics: {
    [passConstants.APPOINTMENT_METRICS_KEY.TODAY]: 0,
    [passConstants.APPOINTMENT_METRICS_KEY.AWAITING_TODAY]: 0,
    [passConstants.APPOINTMENT_METRICS_KEY.FUTURE]: 0
  },
  isAppointmentsCountersFetched: false
}
const getters = {
  appointmentMetrics: (state) => state.appointmentMetrics,
  // totalPassesLength: (state) => state.totalPasses.length,
  // allActivePassesLength: (state) =>
  //   state.totalPasses.filter(
  //     (pass) =>
  //       (pass.child && pass.child.pass_status === 1) || pass.pass_status === 1
  //   ).length,
  // myPassesLength: (state) => state.myPasses.length,
  // myActivePassesLength: (state) =>
  //   state.myPasses.filter(
  //     (pass) =>
  //       (pass.child && pass.child.pass_status === 1) || pass.pass_status === 1
  //   ).length,
  // flaggedPassesLength: (state) =>
  //   state.myPasses.filter(
  //     (pass) =>
  //       pass.badge_flags.extended ||
  //       pass.badge_flags.system_completed ||
  //       pass.badge_flags.min_time
  //   ).length,
  // waitingApprovalLength: (state) => state.waitingApproval.length,
  appTodays: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_today")
    })
    return filteredAptPasses.length
  },
  appTodaysWaiting: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_awaiting")
    })
    return filteredAptPasses.length
  },
  appFutureWaiting: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_future_awaiting")
    })
    return filteredAptPasses.length
  },
  aptPassesCounters: (state) => Array.from(state.aptPassesCounters.values()),
  buildingPassStore: (state, _getters, rootState) => {
    const buildingPassesMap = new Map()
    const allPassesInDashboardCounter = [
      ...(state?.totalPasses || []),
      ...(state?.myPasses || []),
      ...(state?.waitingApproval || [])
    ]
    allPassesInDashboardCounter.forEach((pass) => {
      buildingPassesMap.set(pass.id, pass)
    })
    const dashboardTablePasses = Array.from(
      rootState?.dashboardTable?.passes?.values() || []
    )
    dashboardTablePasses.forEach((pass) => {
      buildingPassesMap.set(pass.id, pass)
    })
    const buildingPasses = Array.from(buildingPassesMap?.values() || [])
    const totalPasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.TOTAL
        )
      }) || []
    const activePasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.ACTIVE
        )
      }) || []
    const myPasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.MY
        )
      }) || []
    const myActivePasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.MY_ACTIVE
        )
      }) || []
    const flaggedPasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.FLAGGED
        )
      }) || []
    const waitingForApprovalPasses =
      buildingPasses?.filter((pass) => {
        return helpers.updateAndFilterPassByPassType(
          pass,
          passConstants.PASS_TYPE_NAMES.WAITING_FOR_APPROVAL
        )
      }) || []
    return {
      totalPasses,
      activePasses,
      myPasses,
      myActivePasses,
      flaggedPasses,
      waitingForApprovalPasses
    }
  },
  totalPassesLength: (_state, getters) =>
    getters?.buildingPassStore?.totalPasses?.length || 0,
  allActivePassesLength: (_state, getters) =>
    getters?.buildingPassStore?.activePasses?.length || 0,
  myPassesLength: (_state, getters) =>
    getters?.buildingPassStore?.myPasses?.length || 0,
  myActivePassesLength: (_state, getters) =>
    getters?.buildingPassStore?.myActivePasses?.length || 0,
  flaggedPassesLength: (_state, getters) =>
    getters?.buildingPassStore?.flaggedPasses?.length || 0,
  waitingApprovalLength: (_state, getters) =>
    getters?.buildingPassStore?.waitingForApprovalPasses?.length || 0,
  isAppointmentsCountersFetched: (state) => state.isAppointmentsCountersFetched
}
const mutations = {
  SET_PASSES(state, data) {
    state.waitingApproval = data.filter(
      (pass) =>
        pass.badge_flags.waiting_approval && helpers.doesCountMyPass(pass)
    )
    state.waitingApproval = state.waitingApproval.filter(
      (e) => !(e.badge_flags.missed || e.badge_flags.canceled)
    )
    state.totalPasses = data.filter(
      (pass) => !pass.badge_flags.waiting_approval && !pass.badge_flags.missed
    )
    state.myPasses = state.totalPasses.filter((pass) =>
      helpers.doesCountMyPass(pass)
    )
  },
  UPDATE_PASS(state, pass) {
    pass.badge_flags = helpers.getBadgeFlags(pass)
    pass.total_time = helpers.getTotalTime(pass)
    pass = helpers.setUserAssignedRoomsPerPass(pass)

    if (pass.approved_at) {
      let shouldAdd = true
      state.totalPasses = state.totalPasses.map((e) => {
        if (e.id === pass.id) {
          shouldAdd = false
          return pass
        }
        return e
      })
      if (shouldAdd) {
        state.totalPasses.push(pass)
      }
      state.myPasses = state.totalPasses.filter((pass) =>
        helpers.doesCountMyPass(pass)
      )
      state.waitingApproval = state.waitingApproval.filter(
        (e) => e.id != pass.id
      )
    } else {
      if (helpers.doesCountMyPass(pass)) {
        let shouldAdd = true

        state.waitingApproval = state.waitingApproval.map((e) => {
          if (e.id === pass.id) {
            shouldAdd = false
            return pass
          }
          return e
        })
        if (shouldAdd) {
          state.waitingApproval.push(pass)
        }

        state.waitingApproval = state.waitingApproval.filter(
          (e) => !(e.badge_flags.missed || e.badge_flags.canceled)
        )
      }
    }
  },
  UPDATE_APP_COUNTERS(state, passesCollection) {
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.aptPassesCounters.set(passObj.id, passObj)
    })
    state.aptPassesCounters = new Map(state.aptPassesCounters)
  },
  DELETE_APP_COUNTERS_BY_ID(state, ids) {
    ids.forEach((id) => state.aptPassesCounters.delete(id))
    state.aptPassesCounters = new Map(state.aptPassesCounters)
  },
  SET_PASSES_FROM_DASHBOARD_TABLE(state, passesCollection) {
    const passes = new Map()
    const existingPasses = Array.from(
      state?.passesFromDashboardTable?.values() || []
    )
    existingPasses.forEach((passObj) => {
      passes.set(passObj.id, passObj)
    })
    passesCollection.forEach((passObj) => {
      passes.set(passObj.id, passObj)
    })
    state.passesFromDashboardTable = new Map(passes)
  },
  SET_APPOINTMENT_METRICS(state, { key, value }) {
    state.appointmentMetrics = {
      ...state.appointmentMetrics,
      [key]: value
    }
  },
  UPDATE_APPOINTMENT_METRICS(state, { key, type }) {
    const metrics = {
      ...state.appointmentMetrics
    }
    if (type === passConstants.APPOINTMENT_METRICS_OPERATIONS.INCREMENT) {
      metrics[key] = metrics[key] + 1
    }
    if (type === passConstants.APPOINTMENT_METRICS_OPERATIONS.DECREMENT) {
      metrics[key] = metrics[key] - 1 < 0 ? 0 : metrics[key] - 1
    }
    state.appointmentMetrics = metrics
  },
  SET_APPOINTMENTS_COUNTERS_FETCHED(state, value) {
    state.isAppointmentsCountersFetched = value
  }
}
const actions = {
  getPassesForCounters(context) {
    const params = {
      type: "all", //my, all, appointments
      status: "both" // both, ended, active
    }
    apiV6nocache
      .get("/admin/users/dashboard/filter", {
        params: params
      })
      .then((response) => {
        let data = response.data.data
        if (data) {
          data = data.map((e) => {
            if (helpers.isPassExceedingSystemEndTime(e)) {
              const completeTime = helpers.getPassEndTime(e)
              if (e.child) {
                e.child.pass_status = 0
                e.child.system_completed = 1
                e.child.completed_at = completeTime
              } else {
                e.pass_status = 0
                e.system_completed = 1
                e.completed_at = completeTime
              }
            }
            e.badge_flags = helpers.getBadgeFlags(e)
            e.total_time = helpers.getTotalTime(e)
            e = helpers.setUserAssignedRoomsPerPass(e)
            return e
          })
          context.commit("SET_PASSES", data)
        }
      })
  },
  getAppointmentsCounters(context) {
    const todays = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      recurrence: false,
      quick_filter: "todays_dashboard",
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    const todaysWaiting = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      quick_filter: "awaiting",
      recurrence: false,
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    const futureWaiting = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      quick_filter: "awaiting",
      recurrence: false,
      between_dates: [
        moment().add(1, "days").format("MM/DD/YYYY").toString(),
        moment().add(1, "years").format("MM/DD/YYYY").toString()
      ]
    }

    Promise.allSettled([
      apiV6nocache.get(`/admin/passes/appointments/filter`, { params: todays }),
      apiV6nocache.get(`/admin/passes/appointments/filter`, {
        params: todaysWaiting
      }),
      apiV6nocache.get(`/admin/passes/appointments/filter`, {
        params: futureWaiting
      })
    ])
      .then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled" && result.value.data.data) {
            context.commit("UPDATE_APP_COUNTERS", result.value.data.data)
          }
        })
        context.commit("SET_APPOINTMENTS_COUNTERS_FETCHED", true)
      })
      .catch(() => {
        context.commit("SET_APPOINTMENTS_COUNTERS_FETCHED", false)
      })
  },
  getAppointmentMetricsCount(context) {
    const todays = {
      count: true,
      recurrence: false,
      quick_filter: "todays_dashboard",
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    const todaysWaiting = {
      count: true,
      recurrence: false,
      quick_filter: "awaiting",
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    const futureWaiting = {
      count: true,
      recurrence: false,
      quick_filter: "awaiting",
      between_dates: [
        moment().add(1, "days").format("MM/DD/YYYY").toString(),
        moment().add(1, "years").format("MM/DD/YYYY").toString()
      ]
    }
    Promise.allSettled([
      apiV6nocache.get(`/admin/passes/appointments/filter`, { params: todays }),
      apiV6nocache.get(`/admin/passes/appointments/filter`, {
        params: todaysWaiting
      }),
      apiV6nocache.get(`/admin/passes/appointments/filter`, {
        params: futureWaiting
      })
    ]).then((results) => {
      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value.data
          if (index === 0) {
            context.commit("SET_APPOINTMENT_METRICS", {
              key: passConstants.APPOINTMENT_METRICS_KEY.TODAY,
              value: data.count
            })
          } else if (index === 1) {
            context.commit("SET_APPOINTMENT_METRICS", {
              key: passConstants.APPOINTMENT_METRICS_KEY.AWAITING_TODAY,
              value: data.count
            })
          } else if (index === 2) {
            context.commit("SET_APPOINTMENT_METRICS", {
              key: passConstants.APPOINTMENT_METRICS_KEY.FUTURE,
              value: data.count
            })
          }
        }
      })
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
