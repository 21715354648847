const LOCAL_STORAGE_KEYS = Object.freeze({
  DASHBOARD_PASS_STATUS: "dashboardPassesStatus",
  DASHBOARD_PASS_TYPE: "dashboardPassesType"
})

const APPOINTMENT_METRICS_KEY = Object.freeze({
  TODAY: "today",
  AWAITING_TODAY: "awaitingToday",
  FUTURE: "future"
})

const APPOINTMENT_METRICS_OPERATIONS = Object.freeze({
  INCREMENT: "increment",
  DECREMENT: "decrement"
})

const PASS_TYPE_NAMES = Object.freeze({
  TOTAL: "total",
  ACTIVE: "active",
  MY: "my",
  MY_ACTIVE: "myActive",
  FLAGGED: "flagged",
  WAITING_FOR_APPROVAL: "waitingForApproval"
})

export default {
  LOCAL_STORAGE_KEYS,
  PASS_TYPE_NAMES,
  APPOINTMENT_METRICS_KEY,
  APPOINTMENT_METRICS_OPERATIONS
}
